import { onMounted, onBeforeUnmount, watch, nextTick } from 'vue';

import type DataGridControl from 'o365.controls.DataGrid.ts';
import type { Location, AreaSelection } from 'o365.modules.SelectionControl.ts';
type Ref<T> = {
    value: T
}

enum KeyCode {
    TAB = 'Tab',
    LEFT = 'ArrowLeft',
    UP = 'ArrowUp',
    RIGHT = 'ArrowRight',
    DOWN = 'ArrowDown',
    F2 = 'F2',
    ESC = 'Escape',
    ENTER = 'Enter',
    DEL = 'Delete',
    SPACE = ' ',
}

export interface NavigationControl {
    renderSelection?: (selection: AreaSelection) => void;
    focusFirstEditableCell?: (index?: number) => Promise<void>;
};

/**
 * Enables all navigation events and rendering 
 */
export default function useDataGridNavigation(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<DataGridControl>,
    rowSelector: string,
    cellSelector: string,
    forceEditMode?: boolean,
    widthScrollerRef?: Ref<HTMLElement>,
    cellEditorRef?: Ref<any>,
    contextMenuRef?: Ref<any>,
    enableDrag?: boolean
    reverseY?: boolean,
    disableSelectionRendering: boolean
}) {

    const { focusFirstEditableCell } = useKeyboardNavigation({
        containerRef: options.containerRef,
        gridControl: options.gridControl,
        rowSelector: options.rowSelector,
        cellSelector: options.cellSelector,
        forceEditMode: options.forceEditMode,
        widthScrollerRef: options.widthScrollerRef,
        reverseY: options.reverseY
    });

    useSelectionMouseEvents({
        containerRef: options.containerRef,
        gridControl: options.gridControl,
        enableDrag: options.enableDrag ?? false,
        forceEditMode: options.forceEditMode ?? false,
        rowSelector: options.rowSelector,
        cellSelector: options.cellSelector,
        contextMenuRef: options.contextMenuRef
    });

    let selectionRenderingObj;;
    if (!options.disableSelectionRendering) {
        selectionRenderingObj = useSelectionRendering({
            containerRef: options.containerRef,
            gridControl: options.gridControl,
            rowSelector: options.rowSelector,
            cellSelector: options.cellSelector
        });
    }

    useGridFocusRendering({
        containerRef: options.containerRef,
        gridControl: options.gridControl,
        rowSelector: options.rowSelector,
        cellSelector: options.cellSelector,
        cellEditorRef: options.cellEditorRef,
        forceEditMode: options.forceEditMode
    });

    const navigationControl = <NavigationControl>{
        renderSelection: selectionRenderingObj?.renderSelection,
        focusFirstEditableCell: focusFirstEditableCell
    };

    return { navigationControl };
}

/**
 * Enables keyboard navigational/editing events
 */
export function useKeyboardNavigation(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<DataGridControl>,
    rowSelector: string,
    cellSelector: string,
    forceEditMode?: boolean,
    widthScrollerRef?: Ref<HTMLElement>,
    reverseY?: boolean
}) {
    const containerRef = options.containerRef;
    const gridControl = options.gridControl;
    const gridFocusControl = GridFocusControl.getGridFocusControl(options.gridControl);
    const gridSelectionInterface = GridSelectionInterface.getGridSelectionInterface(options.gridControl);
    const ariaIndexParser = AriaIndexParser.getAriaIndexParser();

    function cellIsEditable(cell: Location): boolean {
        const column = gridControl.value.dataColumns.columns[cell.x];
        if (column == null) { return false; }
        if (typeof column.editable === 'function') {
            const row = getItemFromRowIndex(cell.y);
            if (row == null) {
                return false;
            } else {
                return column.editable(row);
            }
        } else {
            return column.editable;
        }
    }

    function _isInEditMode() {
        return options.forceEditMode || gridFocusControl.editMode;
    }

    function getItemFromRowIndex(index: number) {
        if (index < 0) {
            if (gridControl.value.dataObject && gridControl.value.dataObject.hasNewRecords) {
                return gridControl.value.dataObject.batchData?.data[Math.abs(index) - 1]
            }
        } else {
            if (gridControl.value.dataObject) {
                return gridControl.value.dataObject.storage.data[index];
            } else {
                return gridControl.value.props.data[index];
            }
        }
    }

    function _syncWidthScroll(cell: Location | null) {
        if (cell == null || !options.widthScrollerRef) { return; }

        const column = gridControl.value.dataColumns.columns[cell.x];
        if (column && !column.pinned) {
            const scrollContainer = options.widthScrollerRef.value.querySelector(".o365-body-horizontal-scroll-viewport");
            if (!scrollContainer) { return; }
            const left = column.left;
            if (scrollContainer.scrollLeft + scrollContainer.clientWidth < left + column.width) {
                scrollContainer.scrollLeft += (left + column.width) - (scrollContainer.scrollLeft + scrollContainer.clientWidth) + 1;
            } else if (left < scrollContainer.scrollLeft) {
                scrollContainer.scrollLeft = left;
            }
        }
    }

    function _scrollToCell(cell: Location) {
        if (!cell) { return; }

        const dataLength = gridControl.value.dataObject ? gridControl.value.dataObject.data.length : gridControl.value.props.data.length;

        if (cell.y < 0 || cell.y >= dataLength) { return; }

        const column = gridControl.value.dataColumns.columns[cell.x];
        if (!column || column.suppressNavigable) { return; }

        const cellY = cell.y * 34;
        if ((containerRef.value.clientHeight + containerRef.value.scrollTop) <= (cellY + 34)) {
            containerRef.value.scrollTop = cellY - containerRef.value.clientHeight + 33;
        } else if (cellY < containerRef.value.scrollTop) {
            containerRef.value.scrollTop = cellY === 0 ? 1 : cellY;
        }
    }

    function _isValidNavigationCell(cell: Location | null) {
        if (cell == null) { return false; }
        const column = gridControl.value.dataColumns.columns[cell.x];

        if (!column) { return false; }
        else if (_isInEditMode() && !cellIsEditable(cell)) { return false; }
        else if (column.suppressNavigable || column.hide) { return false; }
        else if (gridControl.value.dataObject) {
            // If index is negative check for batch records
            const dataObject = gridControl.value.dataObject;
            if (cell.y < 0) {
                if (!dataObject.hasNewRecords || !dataObject.batchData?.data[Math.abs(cell.y) - 1]) {
                    return false;
                }
            } else if (!dataObject.storage.data[cell.y]) {
                return false;
            }
        } else {
            const data = gridControl.value.props.data;
            if (!data[cell.y]) {
                return false;
            }
        }

        if (_isInEditMode()) {
            let row: any = null;
            if (gridControl.value.dataObject) {
                row = cell.y < 0 ? gridControl.value.dataObject.batchData?.data[Math.abs(cell.y) - 1] : gridControl.value.dataObject.storage.data[cell.y];
            } else {
                row = gridControl.value.props.data[cell.y];
            }
            if (!row || row.o_groupHeaderRow) { return null; }
        }

        return true;
    }

    function _isCellGoodToFocusOn(cell: Location) {
        const column = gridControl.value.dataColumns.columns[cell.x];

        return column && !column.hide && !column.suppressNavigable && _cellNodeExists(cell);
    }

    function _setFocusToCell(cell: Location | null) {
        gridSelectionInterface.setSingleSelection(cell);
        gridFocusControl.setFocusToCell(cell);
        // const cellNode = <HTMLElement>containerRef.value.querySelector(ariaIndexParser.getCellSelector(options.rowSelector, options.cellSelector, cell.x, cell.y));
        // cellNode?.focus();
    }

    function _focusElementNode(cell: Location) {
        const cellNode = <HTMLElement>containerRef.value.querySelector(ariaIndexParser.getCellSelector(options.rowSelector, options.cellSelector, cell.x, cell.y));
        cellNode?.focus();
    }

    function _isPotentialCell(cell: Location) {
        // If index is negative check for batch records
        if (gridControl.value.dataObject) {
            const dataObject = gridControl.value.dataObject;
            if (cell.y < 0) {
                if (!dataObject.hasNewRecords) {
                    return false;
                }
            }
        } else if (cell.y < 0) {
            return false;
        }

        const column = gridControl.value.dataColumns.columns[cell.x];

        return !!column;
    }

    function _cellNodeExists(cell: Location) {
        const cellNode = <HTMLElement>containerRef.value.querySelector(ariaIndexParser.getCellSelector(options.rowSelector, options.cellSelector, cell.x, cell.y));
        return !!cellNode;

    }

    function __onShiftRangeSelect(e: KeyboardEvent, key: string, currentCell: Location) {
        let nextCell: Location | null = currentCell;

        while (nextCell && (nextCell === currentCell || !_isValidNavigationCell(nextCell))) {
            if (e.ctrlKey) {
                //
                nextCell = null;
            } else {
                nextCell = _getNextCellToFocusWithoutCtrlPressed(key, nextCell);
            }
        }

        if (nextCell) {
            gridSelectionInterface.setAreaEnd(nextCell);
            _syncWidthScroll(nextCell);
        }
    }

    function _getCellAbove(fromCell: Location | null) {
        if (!fromCell) { return null; }

        const increment = options.reverseY ? 1 : -1;

        const cell = { x: fromCell.x, y: fromCell.y + increment };
        // scrollToCell;
        _scrollToCell(cell);
        // check if node exists
        if (_cellNodeExists(cell)) {
            return cell;
        } else {
            return null;
        }
    }
    function _getCellBellow(fromCell: Location | null) {
        if (!fromCell) { return null; }

        const increment = options.reverseY ? -1 : 1;

        const cell = { x: fromCell.x, y: fromCell.y + increment };
        // scrollToCell;
        // check if node exists
        _scrollToCell(cell);
        if (_cellNodeExists(cell)) {
            return cell;
        } else {
            return null;
        }
    }
    function _getCellToLeft(fromCell: Location | null) {
        if (!fromCell) { return null; }

        const cell = { x: fromCell.x - 1, y: fromCell.y };
        // scrollToCell;
        if (_isPotentialCell(cell)) {
            return cell;
        } else {
            return null;
        }
    }

    function _getCellToRight(fromCell: Location | null) {
        if (!fromCell) { return null; }

        const cell = { x: fromCell.x + 1, y: fromCell.y };
        //check
        //scroll
        if (_isPotentialCell(cell)) {
            return cell;
        } else {
            return null;
        }
    }

    function _getNextCellToFocusWithoutCtrlPressed(key: string, currentCell: Location) {
        let pointer: Location | null = currentCell;
        let finished = false;

        while (!finished) {
            switch (key) {
                case KeyCode.UP:
                    pointer = _getCellAbove(pointer);
                    break;
                case KeyCode.DOWN:
                    pointer = _getCellBellow(pointer);
                    break;
                case KeyCode.RIGHT:
                    pointer = _getCellToRight(pointer);
                    break;
                case KeyCode.LEFT:
                    pointer = _getCellToLeft(pointer);
                    break;
                default:
                    pointer = null;
                    console.warn(`Unknown key for navigation: ${key}`);
            }

            if (pointer) {
                finished = _isCellGoodToFocusOn(pointer);
            } else {
                finished = true;
            }
        }

        return pointer;
    }

    function __navigateToNextCell(e: KeyboardEvent, key: string, currentCell: Location) {
        let nextCell: Location | null = currentCell;

        while (nextCell && (nextCell === currentCell || !_isValidNavigationCell(nextCell))) {
            if (e.ctrlKey) {
                //
                nextCell = null;
            } else {
                nextCell = _getNextCellToFocusWithoutCtrlPressed(key, nextCell);
            }
        }

        if (!nextCell) { return; }
        _syncWidthScroll(nextCell);
        _setFocusToCell(nextCell);
    }

    function _onNavigationKeyDown(e: KeyboardEvent, key: string) {
        if (_isInEditMode()) { return; }

        e.preventDefault();
        if (e.shiftKey) {
            const cell = gridSelectionInterface.areaEnd;
            if (cell != null) {
                __onShiftRangeSelect(e, key, cell);
            }
        } else {
            const cell = gridFocusControl.activeCellLocation;
            if (cell != null) {
                __navigateToNextCell(e, key, cell);
            }
        }
    }

    function _tabToNextCellColumn(fromCell: Location, backwards: boolean) {
        let nextCell: Location | null = fromCell;

        while (nextCell && (nextCell === fromCell || !_isValidNavigationCell(nextCell))) {
            if (backwards) {
                nextCell = _getCellToLeft(nextCell);
            } else {
                nextCell = _getCellToRight(nextCell);
            }
        }

        if (nextCell) {
            _syncWidthScroll(nextCell);
            _setFocusToCell(nextCell);
            return true;
        } else {
            return false;
        }
    }

    function _getFirstRowCell(row: number, editableOnly = false) {
        const columns = gridControl.value.dataColumns.columns;
        for (let i = 0; i < columns.length; i++) {
            const cell: Location = { x: i, y: row };
            const column = columns[i];
            if (!column.hide && !column.suppressNavigable && ((!_isInEditMode() && !editableOnly) || (_isInEditMode() && cellIsEditable(cell)) || (editableOnly && cellIsEditable(cell)))) {
                return { x: i, y: row }
            }
        }
        return null;
    }


    function _getLastRowCell(row: number) {
        const columns = gridControl.value.dataColumns.columns;
        for (let i = columns.length - 1; i >= 0; i--) {
            const cell: Location = { x: i, y: row };
            const column = columns[i];
            if (!column.hide && !column.suppressNavigable && (!_isInEditMode() || (_isInEditMode() && cellIsEditable(cell)))) {
                return { x: i, y: row };
            }
        }
        return null;
    }

    function _onTabKeyDown(e: KeyboardEvent) {
        const currentCell = gridFocusControl.activeCellLocation;
        if (currentCell == null) {
            console.warn(`Can't navigate from null cells`);
            return;;
        }
        const backwards = e.shiftKey;

        const movedToNextCellColumn = _tabToNextCellColumn(currentCell, backwards);

        if (!movedToNextCellColumn) {
            let isNegative = backwards;
            if (currentCell.y < 0) {
                // Could be in batch data, flip backwards in that case
                if (gridControl.value.dataObject?.hasNewRecords) {
                    isNegative = !isNegative;
                }
            }
            const increment = isNegative ? -1 : 1
            let row: number | null = currentCell.y + increment;
            if (_isInEditMode()) {
                let item = getItemFromRowIndex(row);
                if (options.forceEditMode && !item?.isBatchRecord) { item = null; row = null }
                while (row != null && item && item['o_groupHeaderRow']) {
                    row += increment;
                    item = getItemFromRowIndex(row);
                }
            }

            if (row == null) { return; }

            const cell = backwards
                ? _getLastRowCell(row)
                : _getFirstRowCell(row);

            if (_isValidNavigationCell(cell)) {
                _setFocusToCell(cell);
                _syncWidthScroll(cell);
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }

    function _onEnterKeyDown(e: KeyboardEvent) {
        const backwards = e.shiftKey;

        const currentCell = gridFocusControl.activeCellLocation;

        let nextCell: Location | null = null;
        if (backwards) {
            nextCell = _getCellAbove(currentCell);
        } else {
            nextCell = _getCellBellow(currentCell);
        }

        if (nextCell != null && _isValidNavigationCell(nextCell)) {
            let waitForSave = false;
            if (_isInEditMode()) {
                gridFocusControl.editMode = false;
                if (!gridControl.value.state.disableSaveOncurrentIndexChange) {
                    waitForSave = true;
                    gridControl.value.save().then(() => {
                        _setFocusToCell(nextCell);
                    });
                }
            }

            if (!waitForSave) {
                _setFocusToCell(nextCell);
            }
        }
    }

    function _onEscKeyDown(_e: KeyboardEvent) {
        const activeCell = gridFocusControl.activeCellLocation;
        let currentItem: any;
        if (activeCell) {
            currentItem = gridControl.value.dataObject
                ? activeCell.y < 0
                    ? gridControl.value.dataObject.batchData?.data[Math.abs(activeCell.y) - 1]
                    : gridControl.value.dataObject.storage.data[activeCell.y]
                : gridControl.value.props.data[activeCell.y];
        }

        const column = gridControl.value.dataColumns.columns[activeCell.x];
        let field: string | null = null;
        if (column && !column.unbound && _isInEditMode()) {
            field = column.field;
        }

        if (currentItem?.hasChanges) {
            gridControl.value.cancelChanges(currentItem.index, field);
        }

        if (_isInEditMode()) {
            gridFocusControl.exitEditMode();
            if (activeCell != null) {
                _focusElementNode(activeCell);
            }
        }
    }

    function _onF2KeyDown(_e: KeyboardEvent) {
        if (!_isInEditMode()) {
            const cell = gridFocusControl.activeCellLocation;
            if (cell != null) {
                _scrollToCell(cell);
                gridFocusControl.enterEditMode();
            }
        }
    };

    function _onKeyDownWithCtrl(e: KeyboardEvent) {
        switch (e.key) {
            case 'c':
            case 'C':
                if ((e.target as HTMLElement).tagName === 'INPUT') {
                    return false;
                }
                const target: HTMLElement = e.target as any;
                const selection = document.getSelection();
                if (selection && selection.focusNode && target.contains != null) {
                    if (target.contains(selection.focusNode) && selection.toString()) {
                        return false;
                    }
                }
                gridControl.value.selectionControl.copySelection(false, gridControl.value.dataColumns.columns);
                containerRef.value.querySelectorAll('.o365-cell-range-selected, .o365-cell-range-single-cell').forEach(cell => cell.classList.add('o365-cell-copy-highlight-animation'))
                setTimeout(() => {
                    containerRef.value.querySelectorAll('.o365-cell-copy-highlight-animation').forEach(cell => cell.classList.remove('o365-cell-copy-highlight-animation'));
                }, 1001)
                return true;
            case 'v':
            case 'V':
                if ((e.target as HTMLElement).tagName === 'INPUT') {
                    return false;
                }
                gridControl.value.selectionControl.pasteSelection(e, gridControl.value.dataColumns.columns).then((pasted: boolean) => {
                    if (pasted) {
                        containerRef.value.querySelectorAll('.o365-cell-range-selected, .o365-cell-range-single-cell').forEach(cell => cell.classList.add('o365-cell-paste-highlight-animation'))
                        setTimeout(() => {
                            containerRef.value.querySelectorAll('.o365-cell-paste-highlight-animation').forEach(cell => cell.classList.remove('o365-cell-paste-highlight-animation'));
                        }, 1001)
                    }
                });
                return true;
            case `'`:
                _copyFromAbove();
                return true;
            // case 'a':
            // case 'a':
            //     e.preventDefault();
            //     if (selection.value) {
            //         const start = _getFirstRowCell(0);
            //         const end = _getLastRowCell(dataObject.value.data.length - 1)
            //         selection.value.start = start;
            //         selection.value.end = end;
            //         selection.value.renderSelection(containerRef.value);
            //     }
            //     return true;
        }
        return false;
    }

    const _toggleBatchData = (e: KeyboardEvent) => {
        const dataObject = options.gridControl.value.dataObject;
        if (!dataObject.allowInsert || options.gridControl.value.props.hideNewRecords ||  options.gridControl.value.props.disableBatchRecords) {
            return;
        }
        e.preventDefault();
        if (dataObject.batchDataEnabled) {
            if (dataObject.batchData.data.every(x => !x.hasChanges)) {
                options.gridControl.value.closeBatchRecords();
            }
        } else {
            options.gridControl.value.enableBatchRecords();
        }
    };

    function _isEventFromPrintableCharacter(e: KeyboardEvent) {
        if (e.altKey || e.ctrlKey || e.metaKey) { return false; }
        const printableCharacter = e.key.length === 1;
        return printableCharacter;
    }

    function _onKeyDown(e: KeyboardEvent) {
        if (e.ctrlKey) {
            const handled = _onKeyDownWithCtrl(e);
            if (handled) { return; }
        }

        if (_isInEditMode()) { return; }

        const target = <HTMLElement>e.target;
        if (!target.classList.contains('o365-body-cell')) { return null; }

        if (_isEventFromPrintableCharacter(e)) {
            e.preventDefault();
            //_startEditMode(e.key);
            //TODO: Enter edit mode with value e.key
        }
    }

    const handleKeydown = (e: KeyboardEvent) => {
        const key = e.key;
        switch (key) {
            case KeyCode.ENTER:
                _onEnterKeyDown(e);
                break;
            case KeyCode.F2:
                _onF2KeyDown(e);
                break;
            case KeyCode.ESC:
                _onEscKeyDown(e);
                break;
            case KeyCode.TAB:
                e.preventDefault();
                _onTabKeyDown(e);
                break;
            case KeyCode.DEL:
                break;
            case KeyCode.SPACE:
                break;
            case KeyCode.DOWN:
            case KeyCode.UP:
            case KeyCode.LEFT:
            case KeyCode.RIGHT:
                _onNavigationKeyDown(e, key);
                break;
            case 'N':
            // @ts-ignore
            case 'n':
                if (e.altKey) {
                    _toggleBatchData(e);
                    break;
                }
            default:
                if (!options.forceEditMode) {
                    _onKeyDown(e);
                }
        }
    };

    const handlePaste = (e: ClipboardEvent) => {
        if ((e.target as HTMLElement).tagName === 'INPUT') {
            const input = e.target as HTMLInputElement;
            if (input.selectionStart !== 0 && input.selectionEnd !== 0) {
                return;
            }
        }
        gridControl.value.selectionControl.pasteSelection(e, gridControl.value.dataColumns.columns).then((pasted: boolean) => {
            if (pasted) {
                containerRef.value.querySelectorAll('.o365-cell-range-selected, .o365-cell-range-single-cell').forEach(cell => cell.classList.add('o365-cell-paste-highlight-animation'))
                setTimeout(() => {
                    containerRef.value.querySelectorAll('.o365-cell-paste-highlight-animation').forEach(cell => cell.classList.remove('o365-cell-paste-highlight-animation'));
                }, 1001)
            }
        });
    }

    async function _copyFromAbove() {
        const activeCell = gridFocusControl.activeCellLocation;
        if (!activeCell) { return null; }
        const cell = _getCellAbove(activeCell);
        if (_isValidNavigationCell(cell)) {
            const data = gridControl.value.dataObject?.data ?? gridControl.value.props.data;
            const rowAbove = data[cell!.y];
            const currentRow = data[activeCell.y];
            const field = gridControl.value.dataColumns.columns[cell!.x].field
            if (field && currentRow && rowAbove) {
                if (gridFocusControl.editMode) {
                    currentRow[field] += rowAbove[field];
                } else {
                    currentRow[field] = rowAbove[field];
                    gridFocusControl.enterEditMode();
                }
            }
        }

    }

    onMounted(() => {
        containerRef.value.addEventListener('keydown', handleKeydown);
        if (options.forceEditMode) {
            containerRef.value.addEventListener('paste', handlePaste);
        }
    });

    onBeforeUnmount(() => {
        containerRef.value.removeEventListener('keydown', handleKeydown);
        if (options.forceEditMode) {
            containerRef.value.removeEventListener('paste', handlePaste);
        }
    });


    const focusFirstEditableCell = async (index = 0) => {
        const cell = _getFirstRowCell(index, true);
        if (cell) {
            gridSelectionInterface.setSingleSelection(cell);
            gridFocusControl.setFocusToCell(cell);
        }
    };

    return { focusFirstEditableCell };
}

/**
 * Enables selection rendering
 */
export function useSelectionRendering(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<DataGridControl>,
    rowSelector: string,
    cellSelector: string
}) {
    const selectionIndex = 1;

    const ariaIndexParser = AriaIndexParser.getAriaIndexParser();
    const gridSelectionInterface = GridSelectionInterface.getGridSelectionInterface(options.gridControl);

    function _clearClassBind() {
        gridSelectionInterface.selectionClassMap = null;
    }

    function renderSelection(newSelection: AreaSelection) {
        window.requestAnimationFrame(() => {
            _clearClassBind();

            if (!newSelection) { return null; }

            const range = (start: number, stop: number, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
            const negativeCols = newSelection.end.x - newSelection.start.x < 0;
            const negativeRows = newSelection.end.y - newSelection.start.y < 0;

            const columns = range(newSelection.start.x, newSelection.end.x, negativeCols ? -1 : 1);
            const rows = range(newSelection.start.y, newSelection.end.y, negativeRows ? -1 : 1);

            const topRow = negativeRows ? rows[rows.length - 1] : rows[0];
            const bottomRow = negativeRows ? rows[0] : rows[rows.length - 1];
            const leftCol = negativeCols ? columns[columns.length - 1] : columns[0];
            const rightCol = negativeCols ? columns[0] : columns[columns.length - 1];
            rows.forEach((row) => {
                columns.forEach((col) => {
                    const item = options.gridControl.value.dataObject ? options.gridControl.value.dataObject.data[row] : options.gridControl.value.props.data[row];
                    if (!item) { return null; }

                    const classMap = ['o365-cell-range-selected'];

                    if (topRow === row) {
                        // cell.classList.add('o365-cell-range-top');
                        classMap.push('o365-cell-range-top')
                    }
                    if (bottomRow === row) {
                        // cell.classList.add('o365-cell-range-bottom');
                        classMap.push('o365-cell-range-bottom')
                    }
                    if (leftCol === col) {
                        // cell.classList.add('o365-cell-range-left');
                        classMap.push('o365-cell-range-left')
                    }
                    if (rightCol === col) {
                        // cell.classList.add('o365-cell-range-right');
                        classMap.push('o365-cell-range-right')
                    }

                    // cell.classList.add('o365-cell-range-selected', 'o365-cell-range-mark');

                    /*
                    if (!options.gridControl.value.dataObject.data[row].o365_selectionClass) {
                        options.gridControl.value.dataObject.data[row].o365_selectionClass = {};
                    }
                    options.gridControl.value.dataObject.data[row].o365_selectionClass[col] = classMap;
                    */

                    if (!gridSelectionInterface.selectionClassMap) { gridSelectionInterface.selectionClassMap = {}; }
                    if (!gridSelectionInterface.selectionClassMap[row]) { gridSelectionInterface.selectionClassMap[row] = {}; }
                    gridSelectionInterface.selectionClassMap[row][col] = classMap;
                    // cell.classList.add('o365-cell-range-selected', `o365-cell-range-selected-${selectionIndex}`);
                });
            });
        });
    }

    watch(() => options.gridControl.value.selectionControl.selection, (newSelection: AreaSelection, _prevSelection: AreaSelection) => {
        // skipRender = false;
        renderSelection(newSelection);
    });

    return { renderSelection };
}

/**
 * Enables focus rendering and active editing cell switching
 */
export function useGridFocusRendering(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<DataGridControl>,
    rowSelector: string,
    cellSelector: string,
    cellEditorRef?: Ref<any>,
    forceEditMode?: boolean
}) {
    const gridFocusControl = GridFocusControl.getGridFocusControl(options.gridControl);
    const ariaIndexParser = AriaIndexParser.getAriaIndexParser();

    //let activateDebounce;
    function _activateEditor(cellRef?: any) {
        //  if (activateDebounce) { window.clearTimeout(activateDebounce); }
        //    activateDebounce = window.setTimeout(() => {

        nextTick().then(() => {
            if (options.cellEditorRef) {
                const findFunction = (reference: any) => {
                    if (!reference) { return null; }
                    else if (reference.activateEditor) { return reference.activateEditor; }
                    else if (reference.editorRef) { return findFunction(reference.editorRef); }
                    else if (reference.$?.subTree?.component?.ctx) { return findFunction(reference.$.subTree.component.ctx); }
                    else if (reference?.nextElementSibling?.__vnode?.ctx?.exposed?.activateEditor) { return reference.nextElementSibling.__vnode.ctx.exposed.activateEditor; }
                    else if (reference?.nextElementSibling?.tagName === 'INPUT') { reference.nextElementSibling.click(); reference.nextElementSibling.focus(); return null; }
                    else if (reference?.parentElement?.querySelector('input')) { reference.parentElement.querySelector('input').click(); reference.parentElement.querySelector('input').focus(); return null; }
                    else if (reference?.parentElement?.querySelector('textarea')) { reference.parentElement.querySelector('textarea').click(); reference.parentElement.querySelector('textarea').focus(); return null; }
                    else if (reference?.parentElement?.querySelector('select')) { reference.parentElement.querySelector('select').click(); reference.parentElement.querySelector('select').focus(); return null; }
                    else if (reference?.parentElement?.querySelector('.o365-editor')) { reference.parentElement.querySelector('.o365-editor').click(); reference.parentElement.querySelector('.o365-editor').focus(); return null; }
                    else { return null; }
                };
                let activate = findFunction(options.cellEditorRef.value?.$refs?.editorRef);
                if (activate) { activate(); }
            } else if (cellRef) {
                if (gridFocusControl.focusedThroughClick) { gridFocusControl.focusedThroughClick = false; return null; }
                if (typeof cellRef.$el?.click === 'function') { cellRef.$el.click(); }
                nextTick().then(() => {
                    const findFunction = (reference: any) => {
                        if (!reference) { return null; }
                        else if (reference.activateEditor) { return reference.activateEditor; }
                        else if (reference.$refs?.popupRef) { return findFunction(reference.$refs.popupRef); }
                        else if (reference.$refs?.editorRef) { return findFunction(reference.$refs.editorRef); }
                        else if (reference.editorRef) { return findFunction(reference.editorRef); }
                        else if (reference.$?.subTree?.component?.ctx) { return findFunction(reference.$.subTree.component.ctx); }
                        else if (reference.parentElement) {
                            let element = reference.parentElement.querySelector('input');
                            if (element) { element.click(); element.focus(); return null; }
                            element = reference.parentElement.querySelector('textarea');
                            if (element) { element.click(); element.focus(); return null; }
                            element = reference.parentElement.querySelector('select');
                            if (element) { element.click(); element.focus(); return null; }
                        }
                        else { return null; }
                    };
                    let activate = findFunction(cellRef);
                    if (activate) { activate(); }
                });
            }
        });

        //    activateDebounce = null;
        // }, 20);

    }

    if (!options.forceEditMode) {
        watch(() => gridFocusControl.editMode, (newValue: boolean) => {
            if (newValue) {
                _activateEditor();
            }
        });
    }

    let currentIndexDebounce: any;
    watch(() => gridFocusControl.activeCell, (newValue: any) => {
        if (currentIndexDebounce) { window.clearTimeout(currentIndexDebounce); }
        options.containerRef.value.querySelectorAll('.o365-focus-cell').forEach(element => element.classList.remove('o365-focus-cell'));
        if (!newValue) { return; }
        const cell = gridFocusControl.activeCellLocation;
        if (cell == null) { return; }
        const cellNode = <HTMLElement>options.containerRef.value.querySelector(ariaIndexParser.getCellSelector(options.rowSelector, options.cellSelector, cell.x, cell.y));
        if (cellNode) {
            cellNode.classList.add('o365-focus-cell');
            cellNode.focus();

            if (options.forceEditMode) {
                // New records panel 
                const column = options.gridControl.value.dataColumns.columns[cell.x];
                const pinned = column.pinned ?? 'center';
                const rowRefSelector = `editor_row_${pinned}_${cell.y}`;
                const cellRefSelector = `editor_col_${cell.x}`;
                const rowRef = options.gridControl.value.newrecordsRef.$refs[rowRefSelector];
                let cellRef = rowRef.$refs[cellRefSelector];
                if (Array.isArray(cellRef)) { cellRef = cellRef[0]; }
                _activateEditor(cellRef);
            } else if (gridFocusControl.editMode) {
                _activateEditor();
            } else {
                // if (currentIndexDebounce) { window.clearTimeout(currentIndexDebounce); currentIndexDebounceLength = 100; }
                currentIndexDebounce = window.setTimeout(() => {
                    let itemIndex: number | null = null;
                    if (options.gridControl.value.dataObject) {
                        itemIndex = options.gridControl.value.dataObject.data[cell.y]?.index;
                    } else {
                        if (options.gridControl.value.getItemByScrollIndex) {
                            itemIndex = options.gridControl.value.getItemByScrollIndex(cell.y)?.item?.index ?? options.gridControl.value.props.data[cell.y]?.index ?? cell.y;
                        } else {
                            itemIndex = options.gridControl.value.props.data[cell.y]?.index ?? cell.y;
                        }
                    }
                    if (itemIndex == null) {
                        console.warn(`Could not retrieve index for row: ${cell.y}`)
                        return;
                    }
                    options.gridControl.value.setCurrentIndex(itemIndex);
                    currentIndexDebounce = null;
                }, 500);
            }
        }
    });
}

/**
 * Enables selection and edit mode mouse events.
 * Drag select is optional
 */
export function useSelectionMouseEvents(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<DataGridControl>,
    enableDrag: boolean,
    forceEditMode: boolean,
    rowSelector: string,
    cellSelector: string,
    contextMenuRef?: Ref<any>
}) {
    const container = options.containerRef;
    const ariaIndexParser = AriaIndexParser.getAriaIndexParser();
    const gridSelectionInterface = GridSelectionInterface.getGridSelectionInterface(options.gridControl);
    const gridFocusControl = GridFocusControl.getGridFocusControl(options.gridControl);

    function _isInEditMode() {
        return options.forceEditMode || gridFocusControl.editMode;
    }

    function getClosestCellFromEvent(e: MouseEvent) {
        const target = <HTMLElement>e.target;
        const vClosest = <HTMLElement>target.closest(options.cellSelector);
        return vClosest;
    }

    let wasDragging = false;
    let mouseUpOperation: (()=>void) | null = null;

    const handleMouseMove = () => {
        mouseUpOperation = null;
    };

    const handleMouseDown = (e: MouseEvent) => {
        if (e.button !== 0) { return; }
        const vClosest = getClosestCellFromEvent(e)
        if (vClosest && !vClosest.classList.contains('o365-editor-cell')) {
            if (vClosest.getAttribute('o365-field')?.startsWith('o365_') ?? true) { 
                if (_isInEditMode()) {
                    gridFocusControl.exitEditMode();
                }
                gridSelectionInterface.clearSelection();
                gridFocusControl.clearFocus();
                return;
            }

            const col = ariaIndexParser.getColIndex(vClosest);
            const row = ariaIndexParser.getRowIndex(vClosest.closest(options.rowSelector));
            if (col == null || row == null) { return; }

            const suppressedOrders = gridSelectionInterface.getSelectionSuppressedColumns();
            if (suppressedOrders.includes(col)) {
                if (_isInEditMode()) {
                    gridFocusControl.exitEditMode();
                }
                return null;
            }

            gridSelectionInterface.setSingleSelection({ x: col, y: row });
            gridFocusControl.setFocusToCell({ x: col, y: row })

            const column = options.gridControl.value.dataColumns.columns[col];
            if (column && column.singleClickEdit && !e.ctrlKey) {
                let cellIsEditable = false;
                if (typeof column.editable === 'function') {
                    const item = options.gridControl.value.dataObject
                        ? options.gridControl.value.dataObject.data[row]
                        : options.gridControl.value.props.data[row];
                    if (!item || item.isNewRecord) {
                        cellIsEditable = false;
                    } else {
                        cellIsEditable = column.editable(item);
                    }
                } else {
                    cellIsEditable = column.editable;
                }

                const dataItem = options.gridControl.value.dataObject?.data[row];
                if (dataItem && dataItem.isSummaryItem) {
                    cellIsEditable = false;
                }
                

                if (cellIsEditable) {
                    let parsedRowIndex = row;
                    if (options.gridControl.value.dataObject) {
                        parsedRowIndex = options.gridControl.value.dataObject.data[row]?.index ?? row;
                    }
                    options.gridControl.value.setCurrentIndex(parsedRowIndex);
                    if (_isInEditMode()) {
                        gridFocusControl.exitEditMode();
                    }
                    container.value.addEventListener('mousemove', handleMouseMove);
                    mouseUpOperation = () => nextTick().then(() => {
                        window.setTimeout(() => {
                            gridFocusControl.enterEditMode();
                        }, 50);
                    });
                } else if (_isInEditMode()) {
                    gridFocusControl.exitEditMode();
                }
            } else if (_isInEditMode()) {
                gridFocusControl.exitEditMode();
            }

            // vClosest.focus();
            if (options.enableDrag && e.ctrlKey) {
                if ((e.target as HTMLElement).tagName !== 'SELECT' && (e.target as HTMLElement).tagName !== 'INPUT' && (e.target as HTMLElement).tagName !== 'TEXTAREA') {
                    e.preventDefault();
                }
                gridFocusControl.isDragging = true;
                return false;
            } else {
                gridFocusControl.focusedThroughClick = true;
                window.setTimeout(() => {
                    gridFocusControl.focusedThroughClick = false;
                }, 50);
            }
        }
    }
    const handleDrag = (e: MouseEvent) => {
        mouseUpOperation = null;
        if (gridFocusControl.isDragging) {
            const vClosest = getClosestCellFromEvent(e)
            if (vClosest && !vClosest.classList.contains('o365-editor-cell')) {
                e.preventDefault();
                wasDragging = true;
                const col = ariaIndexParser.getColIndex(vClosest);
                const row = ariaIndexParser.getRowIndex(vClosest.closest(options.rowSelector));
                const suppressedOrders = gridSelectionInterface.getSelectionSuppressedColumns();
                if (gridSelectionInterface.areaStart == null || col == null || row == null) { return; }
                const isPositiveSelection = gridSelectionInterface.areaStart.x <= col;
                const startX = gridSelectionInterface.areaStart.x;
                const skipSelection = suppressedOrders.some(order => {
                    const isSuppressed = isPositiveSelection
                        ? startX < order && col >= order
                        : startX > order && col <= order;
                    return isSuppressed;
                });
                if (skipSelection) {
                    if (gridSelectionInterface.areaEnd != null) {
                        gridSelectionInterface.setAreaEnd({ x: gridSelectionInterface.areaEnd.x, y: row });
                    }
                    return;
                }
                gridSelectionInterface.setAreaEnd({ x: col, y: row });
            }
        }
    }

    const handleMouseUp = () => {
        gridFocusControl.isDragging = false;
        if (!wasDragging && mouseUpOperation) {
            mouseUpOperation();
        }
        mouseUpOperation = null;
        wasDragging = false;
         container.value.removeEventListener('mousemove', handleMouseMove);
    }

    const handleContextMenu = async (e: MouseEvent) => {
        if (e.ctrlKey) { return null; }
        // const target = <HTMLElement>e.target;
        // if (target?.tagName === 'A') { return null; }
        const vClosest = getClosestCellFromEvent(e)
        if (!vClosest || (vClosest && vClosest.classList.contains('o365-editor-cell'))) {
            if (options.contextMenuRef?.value.dropdown.isOpen) {
                options.contextMenuRef.value.dropdown.close();
            }
            return null;
        } else if (vClosest.getAttribute('o365-field') === 'o365_Action') { return null; }
        e.preventDefault();
        const rowIndex = ariaIndexParser.getRowIndex(vClosest.parentElement);
        const colIndex = ariaIndexParser.getColIndex(vClosest);
        if (rowIndex == null || colIndex == null) { return; }
        const cell = { x: colIndex, y: rowIndex };

        const hasRangeSelection = !!gridSelectionInterface.selection && !gridSelectionInterface.isSingleSelection;
        let resetSelection = !hasRangeSelection;
        if (hasRangeSelection) {
            resetSelection = !gridSelectionInterface.locationIsInSelection(cell)
        }
        if (resetSelection) {
            gridSelectionInterface.setSingleSelection(cell);
            gridFocusControl.setFocusToCell(cell);

            if (_isInEditMode()) {
                gridFocusControl.exitEditMode();
            }
        }

        if (options.contextMenuRef?.value.dropdown.isOpen) {
            await options.contextMenuRef.value.dropdown.close();
        }

        const activeCell = gridFocusControl.activeCellLocation;
        if (activeCell == null) { return; }
        const { x: col, y: row } = activeCell;

        const item = options.gridControl.value.dataObject
            ? options.gridControl.value.dataObject.data[row]
            : options.gridControl.value.props.data[row];

        options.contextMenuRef?.value.initItemValues({
            column: options.gridControl.value.dataColumns.columns[col],
            row: item,
            rowIndex: row,
            event: e 
        });
        options.contextMenuRef?.value.setLocation(e.x, e.y);
        options.contextMenuRef?.value.dropdown.open();
    }

    const handleDoubleClick = (e: MouseEvent) => {
        const vClosest = getClosestCellFromEvent(e);
        if (!vClosest || vClosest?.classList?.contains('o365-editor-cell')) { return; }
        if (vClosest.getAttribute('o365-field')?.startsWith('o365_')) { return; }
        const col = ariaIndexParser.getColIndex(vClosest);
        if (col == null) { console.warn('Could not parse col index in grid'); return; }
        const column = options.gridControl.value.dataColumns.columns[col];
        let rowIndex = ariaIndexParser.getRowIndex(vClosest.closest(options.rowSelector));
        if (rowIndex == null) { console.warn('Could not parse row index in grid'); return; }

        if (column && column.dblclickHandler) {
            e.preventDefault();
            e.stopPropagation();
            column.dblclickHandler(e, column, options.gridControl.value.dataObject.current, rowIndex);
        }

        if (vClosest && !e.ctrlKey) {
            const cell = { x: col, y: rowIndex };
            gridSelectionInterface.setSingleSelection(cell);
            gridFocusControl.setFocusToCell(cell);

            if (!_isInEditMode()) {
                gridFocusControl.enterEditMode();
            }

        }
    }

    onMounted(() => {
        container.value.addEventListener('mousedown', handleMouseDown);
        if (options.enableDrag) {
            container.value.addEventListener('mouseover', handleDrag);
            window.addEventListener('mouseup', handleMouseUp);
        }

        if (options.contextMenuRef) {
            container.value.addEventListener('contextmenu', handleContextMenu);
        }

        if (!options.forceEditMode) {
            container.value.addEventListener('dblclick', handleDoubleClick);
        }
    });

    onBeforeUnmount(() => {
        container.value.removeEventListener('mousedown', handleMouseDown);
        if (options.enableDrag) {
            container.value.removeEventListener('mouseover', handleDrag);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        if (options.contextMenuRef) {
            container.value.removeEventListener('contextmenu', handleContextMenu);
        }

        if (!options.forceEditMode) {
            container.value.removeEventListener('dblclick', handleDoubleClick);
        }
    });
}

/**
 * Composable that syncs dataobject.allowUpdate with grid focus control
 */
export function useAllowEditWatcher(options: {
    gridControl: Ref<DataGridControl>,
}) {
    const gridFocusControl = GridFocusControl.getGridFocusControl(options.gridControl);

    watch(() => options.gridControl.value.state.allowUpdate, (allow: boolean) => {
        if (!allow && gridFocusControl.editMode) {
            gridFocusControl.exitEditMode();
        }
    });
}


//--------------------------------------------------------------------------------------------------

/**
 * Grid control extension responsible for keeping track of currently focused cell and edit mode.
 */
export class GridFocusControl {
    private _gridControl: DataGridControl;
    isDragging = false;
    activeCell: string | null = null;
    editMode = false;
    focusedThroughClick = false;

    get activeCellLocation() {
        if (!this.activeCell) { return null; }
        return this.stringToCell(this.activeCell);
    }

    static getGridFocusControl(gridControl: Ref<DataGridControl>) {
        if (!gridControl.value.gridFocusControl) {
            gridControl.value.gridFocusControl = new GridFocusControl(gridControl.value);
        }
        return gridControl.value.gridFocusControl;
    }

    constructor(gridControl: DataGridControl) {
        this._gridControl = gridControl;
    }

    setFocusToCell(cell: Location | null) {
        if (cell == null) { return; }
        this.activeCell = this.cellToString(cell);
    }

    clearFocus() {
        this.activeCell = null;
    }

    cellToString(cell: Location) {
        if (!cell) { return null; }
        return `${cell.x}_${cell.y}`;
    }

    stringToCell(cellString: string): Location | null {
        if (!cellString) { return null; }
        return {
            x: parseInt(cellString.split('_')[0]),
            y: parseInt(cellString.split('_')[1]),
        };
    }

    //--- EDIT MODE LOGIC ---

    enterEditMode() {
        if (!this._gridControl.state.allowUpdate) { return null; }
        const cell = this.activeCellLocation;
        if (cell) {
            const column = this._gridControl.dataColumns.columns[cell.x];
            if (!column || !this.cellIsEditable(cell) || column.hide) { return null; }

            const row = this._gridControl.dataObject
                ? this._gridControl.dataObject.data[cell.y]
                : this._gridControl.props.data[cell.y];
            if (!row || row.o_groupHeaderRow) { console.warn(`Got null item at index: ${cell.y}`); return null; }

            if (typeof column.editable === 'function') {
                if (!column.editable(row)) { return null; }
            }

            this.editMode = true;
        }
    }

    exitEditMode() {
        this.editMode = false;
    }

    private cellIsEditable(cell: Location): boolean {
        const getItemFromRowIndex = (index: number) => {
            if (index < 0) {
                if (this._gridControl.dataObject && this._gridControl.dataObject.hasNewRecords) {
                    return this._gridControl.dataObject.batchData?.data[Math.abs(index) - 1]
                }
            } else {
                if (this._gridControl.dataObject) {
                    return this._gridControl.dataObject.storage.data[index];
                } else {
                    return this._gridControl.props.data[index];
                }
            }
        }

        const column = this._gridControl.dataColumns.columns[cell.x];
        if (column == null) { return false; }
        if (typeof column.editable === 'function') {
            const row = getItemFromRowIndex(cell.y);
            if (row == null) {
                return false;
            } else {
                return column.editable(row);
            }
        } else {
            return column.editable;
        }
    }

}

/**
 * Interface between grid control and data object selection control
 */
export class GridSelectionInterface {
    private _gridControl: DataGridControl;
    /**
     * Debounce used to stop recomputing suppressed columns object when drag selecting
     */
    private _suppressedSelectionDebounce: number | null = null;
    /**
     * Cached suppressed object to return in the same drag event
     */
    private _suppressedCache: number[];
    private get _selectionControl() { return this._gridControl.selectionControl; }

    selectionClassMap: any;

    get selection() {
        return this._selectionControl.selection;
    }

    get areaStart() {
        return this._selectionControl.selection?.start;
    }

    get areaEnd() {
        return this._selectionControl.selection?.end;
    }

    get isSingleSelection() {
        return this.selection && this.equalLocations(this.areaStart!, this.areaEnd!);
    }

    static getGridSelectionInterface(gridControl: Ref<DataGridControl>): GridSelectionInterface {
        if (!gridControl.value.gridSelectionInterface) {
            gridControl.value.gridSelectionInterface = new GridSelectionInterface({ gridControl: gridControl });
        }
        return gridControl.value.gridSelectionInterface;
    }

    constructor(options: { gridControl: Ref<DataGridControl> }) {
        this._gridControl = options.gridControl.value;
    }

    equalLocations(a: Location, b: Location) {
        return a.x === b.x && a.y === b.y;
    }

    locationIsInSelection(cell: Location | null) {
        if (cell == null) { return false; }
        if (this.areaEnd == null || this.areaStart == null) { return; }
        const xPositive = this.areaEnd.x - this.areaStart.x >= 0;
        const yPositive = this.areaEnd.y - this.areaStart.y >= 0;

        const xIsBetween = xPositive
            ? this.areaStart.x <= cell.x && cell.x <= this.areaEnd.x
            : this.areaEnd.x <= cell.x && cell.x <= this.areaStart.x;

        const yIsBetween = yPositive
            ? this.areaStart.y <= cell.y && cell.y <= this.areaEnd.y
            : this.areaEnd.y <= cell.x && cell.y <= this.areaStart.y;

        return xIsBetween && yIsBetween;
    }

    //--- Location Setters ---
    setSingleSelection(cell: Location | null) {
        if (cell == null) { return; }
        if (!this._selectionControl.selection || !(this.equalLocations(this._selectionControl.selection.start, cell) && this.equalLocations(this._selectionControl.selection.end, cell))) {
            this._selectionControl.selection = { start: cell, end: cell };
        }
    }

    setAreaSelection(area: AreaSelection | null) {
        if (!area) { return; }
        this._selectionControl.selection = area;
    }

    setAreaStart(cell: Location | null) {
        if (!cell) { return; }
        if (this._selectionControl.selection == null) { return; }
        this._selectionControl.selection.start = cell;
    }

    setAreaEnd(cell: Location | null) {
        if (!cell) { return; }
        if (this._selectionControl.selection == null) { return; }
        if (!this.equalLocations(this._selectionControl.selection.end, cell)) {
            this._selectionControl.selection = {
                start: this._selectionControl.selection.start,
                end: cell
            };
        }
    }

    clearSelection() {
        this._selectionControl.selection = undefined;
    }

    /**
     * Returns object where each key is a suppressed column order
     */
    getSelectionSuppressedColumns(): number[] {
        if (this._suppressedSelectionDebounce) {
            this._setSuppressedSelectionDebounce();
            return this._suppressedCache;
        }

        const suppressedOrders: number[] = [];
        this._gridControl.dataColumns.columns.forEach(col => {
            if (col.suppressSelection) {
                suppressedOrders.push(col.order);
            }
        });
        this._suppressedCache = suppressedOrders;
        this._setSuppressedSelectionDebounce();
        return suppressedOrders;
    }

    /**
     * Reset the suppressed selection cache debounce
     */
    private _setSuppressedSelectionDebounce() {
        if (this._suppressedSelectionDebounce) { window.clearTimeout(this._suppressedSelectionDebounce); }
        this._suppressedSelectionDebounce = window.setTimeout(() => {
            this._suppressedSelectionDebounce = null;
        }, 50);
    };
}


/**
 * Utility class responsible for extracting aria indexes from elements 
 */
export class AriaIndexParser {
    static #isInternalConstructing: boolean;

    private $ariaRowIndex: string;
    private $ariaColIndex: string;
    private static parser: AriaIndexParser;

    static getAriaIndexParser() {
        if (!AriaIndexParser.parser) {
            AriaIndexParser.#isInternalConstructing = true;
            AriaIndexParser.parser = new AriaIndexParser();
            AriaIndexParser.#isInternalConstructing = false;
        }
        return AriaIndexParser.parser;
    }

    constructor() {
        if (!AriaIndexParser.#isInternalConstructing) {
            throw new TypeError('AriaIndexParser is not constructable, use AriaIndexParser.getAriaIndexParser()');
        }
        this.$ariaRowIndex = 'data-o365-rowindex';
        this.$ariaColIndex = 'data-o365-colindex';
    }

    get ariaRowIndexSelector() { return this.$ariaRowIndex; }
    get ariaColIndexSelector() { return this.$ariaColIndex; }

    getRowIndex(element: Element | null) {
        const row = element?.getAttribute(this.$ariaRowIndex)
        return row ? parseInt(row) : null;
    }

    getColIndex(element: Element | null) {
        const col = element?.getAttribute(this.$ariaColIndex)
        return col ? parseInt(col) : null;
    }

    getCellSelector(rowSelector: string, cellSelector: string, x: number, y: number) {
        return `${rowSelector}[${this.$ariaRowIndex}="${y}"] ${cellSelector}[${this.$ariaColIndex}="${x}"]`;
    }
}